// all images imported from images directory
import product_01_image from "../images/405e4be1-3493-49d2-ab92-4eb256200ac5.webp";
import product_02_image from "../images/2c814f26-9d3c-4b66-ae54-003fe4db2cb4.jpeg";
import product_03_image from "../images/d020eb98-5c57-43d6-ba90-969907dc8186.webp";
import product_04_image from "../images/94844930-5c66-4c12-a670-93b048169dbe.webp";
import product_05_image from "../images/02b6e2a7-787c-46cb-b5b3-2dfafb6d23e2.webp";
import product_06_image from "../images/6a0457e3-5606-4bf1-a8ed-ade0a5dc3485.webp";
import product_07_image from "../images/383534d2-68d6-43bb-8ca2-45127018845c.webp";
import product_08_image from "../images/5f20fa5f-87a6-4956-9884-6ebcf76531aa.webp";
import product_09_image from "../images/9e629de6-027e-431c-b3d3-bc2762b8238c.webp";
import product_10_image from "../images/b3c127ef-0a3b-45a9-a8ee-7a5610d2a05e.webp";
import product_11_image from "../images/f358a55b-d1b1-4378-9253-6afdccadf482.jpeg";
import product_12_image from "../images/b8674ebc-27b8-43c0-9e24-afcdb66daea2.jpeg";
import product_13_image from "../images/2a7bba04-88cc-4753-b787-a16d88b7ad34.webp";
import product_14_image from "../images/59cabb59-c25b-4082-a865-c5a9e90510bb.webp";
import product_15_image from "../images/3a9e1b1a-9327-4c6c-959f-a2981565baaa.jpeg";
import product_16_image from "../images/b643ceb5-6a4e-4101-a3dc-91c5327c588a.jpeg";
import product_17_image from "../images/37cd85e0-d6b3-4191-8cd6-b63e531731ef.jpeg";
import product_18_image from "../images/0f273e76-ee34-42e5-b565-500186dc2f5b.jpeg";
import product_19_image from "../images/b33f0b64-fa93-4f15-8c94-21ceb4ce722b.jpeg";
import product_20_image from "../images/1c50817b-2ed5-41a4-8192-12aa169a734b.jpeg";
import product_21_image from "../images/fa3e85c6-813f-41d2-902e-56d9225e6608.jpeg";



const products = [

  {
    id: "001",
    title: "Donar Pitsa",
    price: 60000,
    image01: product_01_image,
   
    category: "Pitsa",

    desc: "Donar go‘shti va haqiqiy motsarella pishlog‘i solingan mazali va to‘yimli pitsa",
  },
  {
    id: "002",
    title: "Salsa",
    price: 55000,
    image01: product_02_image,
   
    category: "Pitsa",

    desc: "Joʻja, salsa sousi, mayin motsarella pishlogʻi, xalapenyo, bulgʻor qalampiri va pomidorlar",
  },
  {
    id: "003",
    title: "Gurme",
    price: 55000,
    image01: product_03_image,
   
    category: "Pitsa",

    desc: "Pizza sous, zaytun, pepperoni, qo'ziqorin, oregano",
  },
  {
    id: "004",
    title: "Super Miks",
    price: 55000,
    image01: product_04_image,
   
    category: "Pitsa",

    desc: "«Super Miks» pitsasi   1 ta pitsada 4 xil sevimli pitsalaringiz birlashmasi 😋",
  },
  {
    id: "005",
    title: "Kartoshka fri",
    price: 16000,
    image01: product_05_image,
   
    category: "Gazaklar",

    desc: "Pechdan yangi uzilgan qarsildoq kartoshka fri",
  },
  {
    id: "006",
    title: "'Po-derevenski' kartoshkasi",
    price: 16000,
    image01: product_06_image,
   
    category: "Gazaklar",

    desc: "Pechdan yangi uzilgan qarsildoq 'po-derevenski' kartoshkasi",
  },
  {
    id: "007",
    title: "Tovuqli Bellisster",
    price: 22000,
    image01: product_07_image,
   
    category: "Gazaklar",

    desc: "Mazali tovuqcha yumshoq tortilya ichida haqiqiy motsarella pishlog'i, pomidor va ranch sous bilan",
  },
  {
    id: "008",
    title: "Go’shtli Bellisster",
    price: 22000,
    image01: product_08_image,
   
    category: "Gazaklar",

    desc: "Mazali donar go’shti yumshoq tortilya ichida haqiqiy motsarella pishlog'i, pomidor, piyoz va barbekyu sousi bilan.",
  },
  {
    id: "009",
    title: "Tovuqli popperslar",
    price: 19000,
    image01: product_09_image,
   
    category: "Gazaklar",

    desc: "Qarsildoq tovuq bo'lakchalari 100% tovuq filesidan tayyorlangan va barbekyu sousi bilan qoplangan.",
  },
  {
    id: "010",
    title: "Coca-Cola 1,5 l",
    price: 15000,
    image01: product_10_image,
   
    category: "Ichimliklar",

    desc: "",
  },
  {
    id: "011",
    title: "'Latte' qahvasi",
    price: 12000,
    image01: product_11_image,
   
    category: "Ichimliklar",

    desc: "",
  },
  {
    id: "012",
    title: "'Amerikano' qahvasi",
    price: 10000,
    image01: product_12_image,
   
    category: "Ichimliklar",

    desc: "",
  },
  {
    id: "013",
    title: "Ananas sharbati",
    price: 15000,
    image01: product_13_image,
   
    category: "Ichimliklar",

    desc: "",
  },
  {
    id: "014",
    title: "'Cappuccino' qahvasi",
    price: 12000,
    image01: product_14_image,
   
    category: "Ichimliklar",

    desc: "",
  },
  {
    id: "015",
    title: "Sinnamon Rollar 8 ta",
    price: 15000,
    image01: product_15_image,
   
    category: "Desertlar",

    desc: "",
  },
  {
    id: "016",
    title: "Shokoladli Fondan",
    price: 19000,
    image01: product_16_image,
   
    category: "Desertlar",

    desc: "",
  },
  {
    id: "017",
    title: "Chizkeyk Laym",
    price: 22000,
    image01: product_17_image,
   
    category: "Desertlar",

    desc: "",
  },
  {
    id: "018",
    title: "Shokoladniy chizkeyk",
    price: 22000,
    image01: product_18_image,
   
    category: "Desertlar",

    desc: "",
  },
  {
    id: "019",
    title: "Firmenniy sous",
    price: 3000,
    image01: product_19_image,
   
    category: "Souslar",

    desc: "",
  },
  {
    id: "019",
    title: "Pishloqli sous",
    price: 3000,
    image01: product_20_image,
   
    category: "Souslar",

    desc: "",
  },
  {
    id: "019",
    title: "Barbekyu sousi",
    price: 3000,
    image01: product_21_image,
   
    category: "Souslar",

    desc: "",
  },
  
 
  
];

export default products;
