import React from "react";
import Slider from "react-slick";



import "../../../styles/slider.css";

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      <div>
        <h1 className="testimonial__title mb-4">
        Donar   <span>kombo </span> 
                </h1>
        <div className=" slider__content d-flex align-items-center gap-3 ">
           
          
          <h6 className="testimonial__subtitle ">Haqiqiy donar go‘shti va mazali motsarella pishlog‘i 
          solingan Donar Pitsa, 1 litrlik Coca-cola hamda 8 ta pepperoni rolllari — 2-3 kishi bemalol 
          to‘ydiradigan kombo
          </h6>
          
        
         
        </div>
      </div>
      <div>
        <h1 className="testimonial__title mb-4">
        Ramazon <span> Set 3</span>  
                </h1>
        <div className=" slider__content d-flex align-items-center gap-3 ">
           
          
          <h6 className="testimonial__subtitle ">Bitta "25 sm" pitsa, pepperoni rollari 4 dona, 
          tovuqli va go'shtli bellisster.
          </h6>
          
        
         
        </div>
      </div>
      <div>
        <h1 className="testimonial__title mb-4">
        Ramazon <span>Set 1 </span> 
                </h1>
        <div className=" slider__content d-flex align-items-center gap-3 ">
           
          
          <h6 className="testimonial__subtitle ">Ikkita 25 sm’lik pitsa: 5 xil pitsa turidan ikkita tanlab olishingiz mumkin.
           Pepperoni va sinnamon rollari, 
          hamda katta 1,5 litrlik kola.
          </h6>
          
        
         
        </div>
      </div>
    </Slider>
  );
};

export default TestimonialSlider;
