import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import logo from "../../assets/images/logo.webp";

import "../../styles/footer.css";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="3" md="4" sm="6">
            <div className=" footer__logo text-start">
              <img className="w-100" src={logo} alt="logo" />
              <h5>Sayt tuzuvchisi: <a href="https://t.me/prodev_22">ProDev22</a></h5>
              <h5>Eslatma: Sayt loyihalarim soni ko'paytirish uchun tuzildi Hech qanday buyurtma asosida emas!</h5>
              
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <h5 className="footer__title">Bellissimo Pizza</h5>
            <ListGroup className="deliver__time-list">
              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Biz haqimizda</span>
             
              </ListGroupItem>

              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Ommaviy ofert</span>
         
              </ListGroupItem>
              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Biz haqimizda</span>
             
              </ListGroupItem>

              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Ommaviy ofert</span>
         
              </ListGroupItem>
              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Biz haqimizda</span>
             
              </ListGroupItem>

            
            </ListGroup>
          </Col>

          
        </Row>

      
      </Container>
    </footer>
  );
};

export default Footer;
