import React, { useRef } from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/common-section/CommonSection";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Login = () => {
  const loginNumberRef = useRef();


  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <Helmet title="Login">
      <CommonSection />
      <section>
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12" className="m-auto text-center">
            <h5 className="text-center">Raqamingizni kiriting</h5>
              <form className="form mb-5" onSubmit={submitHandler}>
              <div className="form__group">
                  <input
                    type="phone"
                    placeholder="Tel"
                    required
                    ref={loginNumberRef}
                  />
                </div>

                <button type="submit" className="addTOCart__btn">
                 <span>Kodni  qabul qilish</span>
                </button>
              </form>
              
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Login;
