import React, { useState, useEffect } from "react";

import Helmet from "../components/Helmet/Helmet.js";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import heroImg from "../assets/images/home.jpg";
import "../styles/hero-section.css";

import { Link } from "react-router-dom";

import Category from "../components/UI/category/Category.jsx";

import "../styles/home.css";

import featureImg01 from "../assets/images/service-01.png";
import featureImg02 from "../assets/images/service-02.png";
import featureImg03 from "../assets/images/service-03.png";

import products from "../assets/fake-data/products.js";

import foodCategoryImg01 from "../assets/images/hamburger.png";
import foodCategoryImg02 from "../assets/images/pizza.png";
import foodCategoryImg03 from "../assets/images/bread.png";

import ProductCard from "../components/UI/product-card/ProductCard.jsx";

import whyImg from "../assets/images/location.png";

import networkImg from "../assets/images/network.png";

import TestimonialSlider from "../components/UI/slider/TestimonialSlider.jsx";
import TestimonialSliderImage from "../components/UI/slider/Slider.jsx";

const featureData = [
  {
    title: "Insonlar va jamoa",
    imgUrl: featureImg01,
    desc: "Xodimlarning muvaffaqiyati - kompaniyaning muvaffaqiyatidir. Jamoada birlashib ishlash yuqori cho'qqilarga erishishga imkon beradi. Biz birgalikda eng oliy maqsadlarga erisha olamiz. Ish beruvchi sifatida biz adolatli mehnat sharoitlari, yaxshi muhit va martaba o'shish imkoniyatnlarini ta'minlaymiz.",
  },

 
  {
    title: "Axborot texnologiyalari va innovatsiyalari",
    imgUrl: featureImg03,
    desc: "Biznesimizni yanada kengaytirish va masofadan turib biznesni yuritish, vaqtimizni tejash va umuman kompaniya boshqaruvini yengillashtirish uchun zamonaviy va innovatsion axborot texnologiyalaridan faol foydalanamiz.",
  },
  {
    title: "Mehmonlar va e'tiborini mijozga qaratish",
    imgUrl: featureImg02,
    desc: "Mehmonlarimiz biz uchun eng muhim qadriyatdir. Biz insonlarni birga bo'lishlari uchun sabab yaratamiz. Har bir mehmon keyingi safar aynan bizni tanlash uchun intilamiz, buning uchun biz uni hohishini his qila olishimiz va amalga oshira olishimiz va amalga oshira olishimiz kerak.",
  },
  {
    title: "Halollik va ishonchlilik",
    imgUrl: featureImg03,
    desc: "Biz o'z ishiga professional darajada yondoshadiganlarni afzal ko'ramiz va buning evasiga ularga benuqson ish sifati, o'zaro manfaatli hamkorlik, halollik va kelishuvlar ijrosini taqdim etamiz.",
  },
  {
    title: "Bizning tasavvurimiz",
    imgUrl: featureImg02,
    desc: "Mahsulot, narx va servisning eng yuqori optimal muvozanatini saqlagan xolda O'zbekistondagi birinchi raqamli pitseriya tarmog'i brendidan xalqaro global brendga aylanish.",
  },
  {
    title: "Standart va sifat",
    imgUrl: featureImg02,
    desc: "Biz doimiy ravishda jarayonlarni standartlashtirish orqali yuqori sifat darajasini ta'minlab boramiz. Standartlarimizga to'liq rioya qilish - sifat garovidir.",
  },
  
];

const Home = () => {
  const [category, setCategory] = useState("ALL");
  const [allProducts, setAllProducts] = useState(products);

  const [hotPizza, setHotPizza] = useState([]);

  useEffect(() => {
    const filteredPizza = products.filter((item) => item.category === "Pizza");
    const slicePizza = filteredPizza.slice(0, 4);
    setHotPizza(slicePizza);
  }, []);

  useEffect(() => {
    if (category === "ALL") {
      setAllProducts(products);
    }

    if (category === "Pitsa") {
      const filteredProducts = products.filter(
        (item) => item.category === "Pitsa"
      );

      setAllProducts(filteredProducts);
    }

    if (category === "Gazaklar") {
      const filteredProducts = products.filter(
        (item) => item.category === "Gazaklar"
      );

      setAllProducts(filteredProducts);
    }

    if (category === "Ichimliklar") {
      const filteredProducts = products.filter(
        (item) => item.category === "Ichimliklar"
      );

      setAllProducts(filteredProducts);
    }
    if (category === "Qahvalar") {
      const filteredProducts = products.filter(
        (item) => item.category === "Qahvalar"
      );

      setAllProducts(filteredProducts);
    }

    if (category === "Desertlar") {
      const filteredProducts = products.filter(
        (item) => item.category === "Desertlar"
      );

      setAllProducts(filteredProducts);
    }
    if (category === "Souslar") {
      const filteredProducts = products.filter(
        (item) => item.category === "Souslar"
      );

      setAllProducts(filteredProducts);
    }
  }, [category]);

  return (
    <Helmet title="Home">
      <section>
        <Container>
          <Row>
            <Col lg="6" md="6">
              <div className="testimonial ">
              <br />
               <br />
               <br />
               <br />
               <br />
                <p className="testimonial__desc">
                 
                </p>

                <TestimonialSlider />
              </div>
            </Col>
            <Col lg="6" md="6">
              <div className="testimonial ">
                

                <TestimonialSliderImage />
              </div>
            </Col>

            
          </Row>
        </Container>
      </section>
     

      

      

      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2>Bizning menu</h2>
            </Col>

            <Col lg="12">
              <div className="food__category d-flex align-items-center justify-content-center gap-4">
                
                <button
                  className={`d-flex align-items-center gap-2 ${
                    category === "Pitsa" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("Pitsa")}
                >
                  
                  Pitsa
                </button>

                <button
                  className={`d-flex align-items-center gap-2 ${
                    category === "Gazaklar" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("Gazaklar")}
                >
                  
                  Gazaklar
                </button>
                <button
                  className={`d-flex align-items-center gap-2 ${
                    category === "Ichimliklar" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("Ichimliklar")}
                >
                  
                  Ichimliklar
                </button>

                
                <button
                  className={`d-flex align-items-center gap-2 ${
                    category === "Desertlar" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("Desertlar")}
                >
                  
                  Desertlar
                </button>

                <button
                  className={`d-flex align-items-center gap-2 ${
                    category === "Souslar" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("Souslar")}
                >
                  
                  Souslar
                </button>
              </div>
            </Col>

            {allProducts.map((item) => (
              <Col lg="3" md="4" sm="6" xs="6" key={item.id} className="mt-5">
                <ProductCard item={item} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center">
            
              
              <h2 className="feature__title">
                Biz <span>haqimizda</span>
              </h2>
              
            </Col>

            {featureData.map((item, index) => (
              <Col lg="4" md="6" sm="6" key={index} className="mt-5">
                <div className="feature__item text-center px-5 py-3">
                  
                  <h5 className=" fw-bold mb-3">{item.title}</h5>
                  <p className=" fw-bold mb-3">{item.desc}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

   

      
    </Helmet>
  );
};

export default Home;
